import React from 'react';
import { nanoid } from 'nanoid';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import Typography from '@material-ui/core/Typography';
import { AgeDiv, StyledTextField, FormStyles } from './styles';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import update from 'immutability-helper';

import {
  isBlank,
  advertisementTypeValuesValidationCheck
} from '../../utils/validations';
import { removeWhiteSpaces, changeAgeFieldsIfEmpty } from '../../utils/helper';
import {
  setPlacementErrorField,
  setErrorFieldTrue,
  setVideoContentFieldErrorTrue,
  filterPreExstingValuesFromCheck,
  getErrorMessage
} from '../../utils/advertisementValidationsHelper';

import Toaster from '../Common/Toaster';
import Multiselect from '../Common/Multiselect';
import NumberInput from '../Common/NumberInput';
import GenderRadio from '../Common/GenderRadio';

import AdvertisementType from '../AdvertisementType';

import { withSnackbar } from 'notistack';

import {
  AIRLINE_ALLOCAION_OPTIONS,
  CONTENT,
  ADVERTISEMENT_TYPE
} from '../../constants';
import { set, cloneDeep } from 'lodash';

const requiredFormFields = [
  'advertisementId',
  'advertisementName',
  'airlineAllocation',
  'advertisementType'
];

const contentIdFields = [
  'contentIdJT',
  'contentIdID',
  'contentIdGA',
  'contentIdIU',
  'contentIdIP'
];

class AddEditCampaignForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      fields: {},
      error: {}
    };
  }

  componentDidMount() {
    let fields = this.state.fields;
    const { campaignRow, advertisement } = this.props;
    const { alreadyOccupiedAdvertismentValues } = advertisement;

    if (isEmpty(this.props.editRow)) {
      fields.advertisementId = `${campaignRow.campaignId}_${
        campaignRow.provider
      }_${nanoid(10)}`;
      fields.content = [];
      fields.gender = ['male', 'female'];
      this.setState({
        fields,
        isEdit: false
      });
    } else {
      const { editRow } = this.props;
      let editRowCloned = cloneDeep(editRow);
      this.setState({
        fields: editRowCloned,
        isEdit: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.advertisement, this.props.advertisement)) {
      this.processResponse();
    }
  }

  processResponse = () => {
    if (!this.props.advertisement.isAddAdversimentStatus) {
      this.setState({
        showToaster: true,
        toasterMessage: 'Something Went Wrong!!',
        toasterType: 'error'
      });
    }
  };

  handleAdvertisementTypeChangeValue = ({ target }) => {
    let fields = cloneDeep(this.state.fields);

    set(fields, `${[target.type]}.${target.id}`, target.value);

    this.setState({
      fields
    });

    this.props.setFormFields(fields);
  };

  handleInputChange = e => {
    let fields = this.state.fields;
    fields[e.target.id] = e.target.value;
    this.setState({
      fields
    });
    this.props.setFormFields(fields);
  };

  enqueueErrorMessage = (data, field) => {
    const { classes } = this.props;
    const snackbarVariant = {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    };

    let self = this;
    data.forEach(function(d, index) {
      const msg = getErrorMessage(
        d,
        field,
        self.props.advertisementConfig,
        self.state.fields
      );
      self.props.enqueueSnackbar(msg, snackbarVariant);
    });
  };

  processValidationErrors = data => {
    const { fields } = this.state;
    const { advertisementType } = fields;
    if (!advertisementType) {
      return {};
    }
    let error = {};
    if (
      advertisementType === 'leaderboard' ||
      advertisementType === 'preroll' ||
      advertisementType === 'midroll'
    ) {
      this.enqueueErrorMessage(data, 'placementIndex');
      error = setPlacementErrorField(data, 'placementIndex');
    }
    if (advertisementType === 'footer') {
      this.enqueueErrorMessage(data, 'pageToInclude');
      error = setErrorFieldTrue(data, 'pageToInclude');
    }
    if (advertisementType === 'popUp') {
      this.enqueueErrorMessage(data, 'pageToInclude');
      error = setErrorFieldTrue(data, 'pageToInclude');
    }
    return error;
  };

  validateForm() {
    const { fields } = this.state;
    const { advertisement, campaignRow } = this.props;
    const { alreadyOccupiedAdvertismentValues } = advertisement;
    let error = [];
    let isFormValid = true;
    for (let i in requiredFormFields) {
      if (isBlank(fields[requiredFormFields[i]])) {
        error[requiredFormFields[i]] = true;
        isFormValid = false;
      } else {
        error[requiredFormFields[i]] = false;
      }
    }

    if (error.airlineAllocation === false) {
      for (let i in fields.airlineAllocation) {
        const objIndex = fields.content.findIndex(
          obj => obj.airline == fields.airlineAllocation[i]
        );
        if (objIndex === -1) {
          error[`contentId${fields.airlineAllocation[i]}`] = true;
          isFormValid = false;
        } else {
          error[`contentId${fields.airlineAllocation[i]}`] = false;
        }
      }

      let extendedFields = cloneDeep(fields);
      extendedFields.startDate = campaignRow.startDate;
      extendedFields.endDate = campaignRow.endDate;

      if (!isEmpty(this.props.editRow)) {
        filterPreExstingValuesFromCheck(extendedFields, this.props.editRow);
      }

      let validationError = advertisementTypeValuesValidationCheck(
        extendedFields,
        alreadyOccupiedAdvertismentValues
      );

      if (
        validationError &&
        fields.advertisementType &&
        validationError[fields.advertisementType] &&
        validationError[fields.advertisementType].status === true
      ) {
        isFormValid = false;
        error[fields.advertisementType] = this.processValidationErrors(
          validationError[fields.advertisementType].data
        );
      }
    }

    this.setState({
      error
    });
    return isFormValid;
  }

  handleFormSubmit = e => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = this.state.fields;
      fields = removeWhiteSpaces(fields);
      fields = changeAgeFieldsIfEmpty(fields);
      if (this.state.isEdit) {
        this.props.editAdvertisement(fields);
      } else {
        fields.campaign = this.props.campaignRow.id;
        this.props.addNewAdvertisement(fields);
      }
    }
  };

  handleToasterClose = () => {
    this.setState({
      showToaster: false
    });
  };

  getTypeByContentId = (airline, value) => {
    const { advertisementConfig } = this.props;
    const { contentIdData, configData } = advertisementConfig;
    if (contentIdData) {
      const obj = contentIdData.filter(function(item) {
        return item.code === value;
      });

      if (obj[0]) {
        return obj[0].type;
      }
      return '';
    }

    return '';
  };

  setContentField = (fields, id, value) => {
    const airline = id.substring(9);
    const { content } = fields;

    if (!fields.content) {
      fields.content = [];
    }
    const objIndex = content.findIndex(obj => obj.airline == airline);
    let type = this.getTypeByContentId(airline, value);
    if (objIndex === -1) {
      fields.content.push({ airline, contentId: value, type });
    } else {
      fields.content[objIndex] = { airline, contentId: value, type };
    }
    return fields;
  };

  checkAirlineInPLacementIndex = (fields, airlines) => {
    let airlineKeys = Object.keys(
      fields[fields.advertisementType].placementIndex
    );
    for (let i = 0; i < airlineKeys.length; i++) {
      if (airlines.indexOf(airlineKeys[i]) === -1) {
        delete fields[fields.advertisementType].placementIndex[airlineKeys[i]];
      }
    }
  };

  checkAirlineForGivenField = (fields, airlines, fieldName) => {
    let data = fields[fields.advertisementType][fieldName];
    for (let i = 0; i < data.length; i++) {
      let airline = data[i].airline;
      if (airlines.indexOf(airline) === -1) {
        data.splice(i, 1);
        i--;
      }
    }
  };

  multiSelectValue = (id, value) => {
    let fields = this.state.fields;
    if (
      id === 'airlineAllocation' &&
      fields.advertisementType &&
      fields[fields.advertisementType]
    ) {
      switch (fields.advertisementType) {
        case 'leaderboard':
          if (fields[fields.advertisementType].placementIndex) {
            this.checkAirlineInPLacementIndex(fields, value);
          }
          if (fields[fields.advertisementType].pageToInclude) {
            this.checkAirlineForGivenField(fields, value, 'pageToInclude');
          }
        case 'preroll':
        case 'midroll':
          if (fields[fields.advertisementType].placementIndex) {
            this.checkAirlineInPLacementIndex(fields, value);
          }
          if (fields[fields.advertisementType].videosCategory) {
            this.checkAirlineForGivenField(fields, value, 'videosCategory');
          }
      }
    }
    if (contentIdFields.indexOf(id) != -1) {
      fields = this.setContentField(fields, id, value);
    } else {
      fields[id] = value;
    }
    this.setState({
      fields
    });
    this.props.setFormFields(fields);
  };

  getContentDataByAirlines = (airline, advertisementType) => {
    const { advertisementConfig } = this.props;
    const { contentIdData, configData } = advertisementConfig;
    const advertisementTypeTags = configData['advertisement-type-tags'] || [];
    let tagObjArray = [];
    const tagObj = advertisementTypeTags.filter(function(item) {
      return item.name === advertisementType;
    });

    let filteredContentIdData = contentIdData.filter(function(content) {
      if (tagObj[0]) {
        if (
          advertisementType === 'preroll' ||
          advertisementType === 'midroll'
        ) {
          return (
            content.airline === airline &&
            content.tags.indexOf(tagObj[0].code) !== -1 &&
            (content.type === 'image' || content.type === 'video')
          );
        } else {
          const tagCode =
            (content.airline === 'IP' || content.airline === 'IU') &&
            advertisementType === 'leaderboard'
              ? 'adtype_leaderboard'
              : tagObj[0].code;
          return (
            content.airline === airline && content.tags.indexOf(tagCode) !== -1
          );
        }
      } else {
        return content.airline === airline;
      }
    });

    return filteredContentIdData;
  };

  getContentFieldData = airline => {
    const { fields } = this.state;
    const { content } = fields;
    const item = content.filter(function(item) {
      return item.airline === airline;
    });
    if (item.length > 0) {
      return [item[0].contentId];
    }

    return [];
  };

  render() {
    const { classes, advertisementConfig, pagesToInclude } = this.props;
    const { configData, videoContent, contentIdData } = advertisementConfig;
    const { pagesToIncludeData } = pagesToInclude;
    const {
      fields,
      error,
      toasterMessage,
      toasterType,
      showToaster
    } = this.state;

    let airlineSelection = fields.airlineAllocation || [];
    let advertisementType = fields.advertisementType;
    let self = this;
    return (
      <div>
        <Toaster
          message={toasterMessage}
          type={toasterType}
          open={showToaster}
          handleClose={this.handleToasterClose}
        />
        <div className={classes.heading}>{'Advertisement Configuration'}</div>
        <div className={classes.fullWidthDiv}>
          <Typography
            className={classes.secondHeading}
            variant="h6"
            gutterBottom
          >
            Advertisement Unit Configuration
          </Typography>
        </div>
        <form
          method="post"
          name="addAdvertisementForm"
          onSubmit={this.handleFormSubmit}
        >
          <StyledTextField
            fullWidth
            id="advertisementId"
            label="Advertisement Id"
            placeholder="Enter Advertisement Id"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            variant="filled"
            onChange={this.handleInputChange}
            value={fields.advertisementId}
            error={error.advertisementId}
            helperText={
              error.advertisementId ? 'Please enter Advertisement Id' : null
            }
            disabled
          />
          <StyledTextField
            fullWidth
            error={error.advertisementName}
            id="advertisementName"
            name="advertisementName"
            label="Advertisement Name"
            margin="normal"
            variant="outlined"
            onChange={this.handleInputChange}
            value={fields.advertisementName}
            helperText={
              error.advertisementName ? 'Please enter Advertisement Name' : null
            }
            disabled={this.state.isEdit}
          />
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="airlineAllocationLabel"
              label="Airline Allocation"
              elementId="airlineAllocation"
              options={AIRLINE_ALLOCAION_OPTIONS || []}
              multiple={true}
              setValue={this.multiSelectValue}
              error={error.airlineAllocation}
              defaultValue={fields.airlineAllocation || []}
              disabled={this.state.isEdit}
            />
          </div>
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="contentIdLabel"
              label="Advertisement Type"
              elementId="advertisementType"
              options={ADVERTISEMENT_TYPE || []}
              multiple={false}
              setValue={this.multiSelectValue}
              error={error.advertisementType}
              defaultValue={fields.advertisementType || []}
              disabled={this.state.isEdit}
            />
          </div>
          <AdvertisementType
            handleChange={this.handleAdvertisementTypeChangeValue}
            fields={fields}
            pagesToInclude={pagesToIncludeData}
            videoContent={videoContent}
            error={error}
          />
          {airlineSelection.map(function(value, index) {
            return (
              <div className={classes.fullWidthDiv}>
                <Multiselect
                  labelId="contentIdLabel"
                  label={`Content Id ${value}`}
                  elementId={`contentId${value}`}
                  options={
                    self.getContentDataByAirlines(value, advertisementType) ||
                    []
                  }
                  multiple={false}
                  setValue={self.multiSelectValue}
                  error={error[`contentId${value}`]}
                  defaultValue={self.getContentFieldData(value) || []}
                />
              </div>
            );
          })}
          <div className={classes.fullWidthDiv}>
            <Typography
              className={classes.secondHeading}
              variant="h6"
              gutterBottom
            >
              Filters
            </Typography>
          </div>
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="originLabel"
              label="Origin"
              elementId="origin"
              options={configData['airport-codes'] || []}
              multiple={true}
              setValue={this.multiSelectValue}
              defaultValue={fields.origin || []}
            />
          </div>
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="destinationLabel"
              label="Destination"
              elementId="destination"
              options={configData['airport-codes'] || []}
              multiple={true}
              setValue={this.multiSelectValue}
              defaultValue={fields.destination || []}
            />
          </div>
          <AgeDiv>
            <NumberInput
              label="Min Age"
              name="minAge"
              id="minAge"
              handleChange={this.handleInputChange}
              defaultValue={fields.minAge || ''}
            />
            <NumberInput
              label="Max Age"
              name="maxAge"
              id="maxAge"
              handleChange={this.handleInputChange}
              defaultValue={fields.maxAge || ''}
            />
          </AgeDiv>
          <div className={classes.fullWidthDiv}>
            <GenderRadio
              handleChange={this.handleInputChange}
              defaultValue={fields.gender || ''}
            />
          </div>
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="allowedNationalitiesLabel"
              label="Allowed Nationalities"
              elementId="allowedNationalities"
              options={configData.nationality || []}
              multiple={true}
              setValue={this.multiSelectValue}
              defaultValue={fields.allowedNationalities || []}
            />
          </div>
          {
            // <StyledTextField
            //   fullWidth
            //   id="keywords"
            //   label="Enter Keywords"
            //   name="keywords"
            //   margin="normal"
            //   onChange={this.handleInputChange}
            //   value={fields.keywords}
            //   variant="outlined"
            // />
          }
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="restrictToLanguagesLabel"
              label="Restrict to Languages"
              elementId="restrictToLanguages"
              options={configData.language || []}
              multiple={true}
              setValue={this.multiSelectValue}
              defaultValue={fields.restrictToLanguages || []}
            />
          </div>
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="professionLabel"
              label="Profession"
              elementId="profession"
              options={configData.profession || []}
              multiple={true}
              setValue={this.multiSelectValue}
              defaultValue={fields.profession || []}
            />
          </div>
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="preferenceLabel"
              label="Preferences"
              elementId="preference"
              options={configData.preferences || []}
              multiple={true}
              setValue={this.multiSelectValue}
              defaultValue={fields.preference || []}
            />
          </div>
          <div className={classes.fullWidthDiv}>
            <Multiselect
              labelId="citiesLabel"
              label="Cities"
              elementId="cities"
              options={configData['airport-codes'] || []}
              multiple={true}
              setValue={this.multiSelectValue}
              defaultValue={fields.cities || []}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            startIcon={<SaveIcon />}
            type="submit"
            id="save_ad_btn"
          >
            Save
          </Button>
        </form>
      </div>
    );
  }
}

export default withStyles(FormStyles)(withSnackbar(AddEditCampaignForm));
