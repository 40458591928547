import { handleActions } from 'redux-actions';
import * as actionTypes from '../constants/actionTypes';
import update from 'immutability-helper';

const initalState = {
  status: 'init',
  pagesToIncludeData: []
};

const getPagesToIncludeStart = (state, action) =>
  update(state, {
    status: { $set: 'init' },
    pagesToIncludeData: { $set: null }
  });

const getPagesToIncludeSuccess = (state, action) =>
  update(state, {
    status: { $set: true },
    pagesToIncludeData: { $set: action.payload }
  });

const getPagesToIncludeError = (state, action) =>
  update(state, {
    status: { $set: false },
    pagesToIncludeData: { $set: [] }
  });

export default handleActions(
  {
    [actionTypes.GET_PAGES_TO_INCLUDE_START]: getPagesToIncludeStart,
    [actionTypes.GET_PAGES_TO_INCLUDE_SUCCESS]: getPagesToIncludeSuccess,
    [actionTypes.GET_PAGES_TO_INCLUDE_ERROR]: getPagesToIncludeError
  },
  initalState
);
