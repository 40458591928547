import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, ERROR_MESSAGE } from '../../constants';
import Campaign from './Campaign';
import {
  addNewCampaignAction,
  editCampaignAction,
  listCampaignsActions,
  deleteCampaignAction,
  syncCampaigns,
  setCampaignCollapseRowIdStatus
} from '../../actions/campaignAction';

import {
  addNewAdvertisementAction,
  editAdvertisementAction,
  deleteAdvertisementAction,
  getContentData
} from '../../actions/advertisementAction';

import { loginAction, logout } from '../../actions/loginAction';

import { getPagesToInclude } from '../../actions/includePagesAction';

const mapStateToProps = state => {
  const { campaign, advertisement, advertisementConfig, login, pagesToInclude } = state;
  return {
    campaign,
    advertisement,
    advertisementConfig,
    login,
    pagesToInclude
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchGetPagesToInclude() {
    dispatch(getPagesToInclude());
  },
  addNewCampaign(data) {
    dispatch(addNewCampaignAction(data));
  },
  editCampaign(data) {
    dispatch(editCampaignAction(data));
  },
  listCampaigns() {
    dispatch(listCampaignsActions());
  },
  deleteCampaign(id) {
    dispatch(deleteCampaignAction(id));
  },
  addNewAdvertisement(data) {
    dispatch(addNewAdvertisementAction(data));
  },
  editAdvertisement(data) {
    dispatch(editAdvertisementAction(data));
  },
  deleteAdvertisement(id) {
    dispatch(deleteAdvertisementAction(id));
  },
  syncCampaigns(campaigns) {
    dispatch(syncCampaigns(campaigns));
  },
  dispatchLogout() {
    dispatch(logout());
  },
  setCampaignCollapseRowIdStatus(statusObj) {
    dispatch(setCampaignCollapseRowIdStatus(statusObj));
  },
  exportCampaigns() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      axios({
        method: 'GET',
        url: `${API_BASE_URL}/campaigns/app/export`,
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(function(response) {
          if (response.status === 200) {
            resolve(response.data.data);
          } else {
            reject(response);
          }
        })
        .catch(function(e) {
          if (e.toJSON().message === ERROR_MESSAGE['401']) {
            dispatch(logout());
          }
          reject(e);
        });
    });
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Campaign)
);
