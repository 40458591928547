import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Advertisement from './index';
import {
  getAdvertisementById,
  getAdvertismentConfigData,
  getAdvertismentVideoContent,
  getContentData
} from '../../actions/advertisementAction';
import { getPagesToInclude } from '../../actions/includePagesAction';

const mapStateToProps = state => {
  const { advertisement, advertisementConfig, pagesToInclude } = state;
  return {
    advertisement,
    advertisementConfig,
    pagesToInclude
  };
};

const mapDispatchToProps = {
  getAdvertisementById,
  getAdvertismentConfigData,
  getAdvertismentVideoContent,
  getContentData,
  getPagesToInclude
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Advertisement)
);
