import React from 'react';
import { FullWidthContainer, StyledTextField } from './styles';
import Multiselect from '../Common/Multiselect';
import { PAGES_TO_INCLUDE } from '../../constants';
// import isEqual from 'lodash/isEqual';

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  handleInputChange = (id, value) => {
    this.props.handleChange({
      target: {
        type: 'footer',
        id: id,
        value: value
      }
    });
  };

  render() {
    const { fields } = this.props;
    return (
      <div>
        <FullWidthContainer>
          <Multiselect
            labelId="pageToIncludeLabel"
            label="Pages to Include"
            elementId="pageToInclude"
            options={this.props.pagesToInclude ?? PAGES_TO_INCLUDE}
            multiple={true}
            setValue={this.handleInputChange}
            defaultValue={fields.pageToInclude || []}
            error={this.props.error.pageToInclude || false}
          />
        </FullWidthContainer>
        <StyledTextField
          fullWidth
          id="targetLinkOnline"
          label="Target Link (Online) (Embedded - src link)"
          margin="normal"
          onChange={e => this.handleInputChange(e.target.id, e.target.value)}
          value={fields.targetLinkOnline}
          variant="outlined"
        />
        <StyledTextField
          fullWidth
          id="targetLinkOffline"
          label="Target Link (Offline) (Embedded - src link)"
          margin="normal"
          onChange={e => this.handleInputChange(e.target.id, e.target.value)}
          value={fields.targetLinkOffline}
          variant="outlined"
        />
      </div>
    );
  }
}

export default Footer;
