import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import CustomTable from '../Common/Table/index';
import { Container, StyledButton, ViewHeader } from './styles';
import Dialog from '../Common/Dialog';
import Toaster from '../Common/Toaster';
import isEmpty from 'lodash/isEmpty';
import { ADVERTISEMENT_TYPE } from '../../constants';

class Advertisement extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      list: true,
      advertisements: [],
      openDialog: {
        edit: false,
        delete: false
      },
      toaster: {
        show: false,
        message: '',
        type: ''
      },
      selectedId: ''
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.advertisement.getAdvertisementStatus !=
      this.props.advertisement.getAdvertisementStatus
    ) {
      this.processEditAdvertisement();
    }
    if (
      prevProps.advertisement.deleteAdvertisementStatus !==
      this.props.advertisement.deleteAdvertisementStatus
    ) {
      this.processDeleteResponse();
    }
  }

  processEditAdvertisement = () => {
    if (this.props.advertisement.getAdvertisementStatus === true) {
      const data = this.props.advertisement.fetchedAdvertisementData;
      this.props.handleAdvertisementEdit(data, this.props.campaignRow);
    }
  };

  processDeleteResponse = () => {
    if (this.props.advertisement.deleteAdvertisementStatus === 'success') {
      this.setState({
        showToaster: true,
        toasterMessage: 'Successfully deleted',
        toasterType: 'success'
      });
      this.props.reloadAdvertisment(true);
    } else if (this.props.advertisement.deleteAdvertisementStatus === 'error') {
      this.setState({
        showToaster: true,
        toasterMessage: 'Something went wrong',
        toasterType: 'error'
      });
    }
  };

  handleAdvertisementEdit = row => {
    const { advertisementConfig, pagesToInclude } = this.props;
    const { configData, videoContent, contentIdData } = advertisementConfig;
    const { pagesToIncludeData } = pagesToInclude;

    if (isEmpty(configData)) {
      this.props.getAdvertismentConfigData();
    }

    if (isEmpty(pagesToIncludeData)) {
      this.props.getPagesToInclude();
    }

    // if (isEmpty(videoContent)) {
    //   this.props.getAdvertismentVideoContent();
    // }

    if (isEmpty(contentIdData)) {
      this.props.getContentData();
    }

    this.props.getAdvertisementById(row._id);
  };

  handleAdvertisementDelete = row => {
    this.setState({
      openDialog: {
        delete: true
      },
      selectedId: row._id
    });
  };

  handleDeleteDialogClose = () =>
    this.setState({
      openDialog: {
        delete: false
      }
    });

  handleDeleteDialogOkay = () => {
    this.props.deleteAdvertisement(this.state.selectedId);
    this.setState({
      openDialog: {
        delete: false
      }
    });
  };

  handleAvertisementAdd = campaignRow => {
    const { advertisementConfig, pagesToInclude } = this.props;
    const { configData, videoContent, contentIdData } = advertisementConfig;
    const { pagesToIncludeData } = pagesToInclude;

    if (isEmpty(configData)) {
      this.props.getAdvertismentConfigData();
    }

    if (isEmpty(pagesToIncludeData)) {
      this.props.getPagesToInclude();
    }
    // if (isEmpty(videoContent)) {
    //   this.props.getAdvertismentVideoContent();
    // }

    if (isEmpty(contentIdData)) {
      this.props.getContentData();
    }

    this.props.addAdvertisementOpen(campaignRow);
  };

  handleToasterClose = () =>
    this.setState({
      toaster: {
        show: false
      }
    });

  render() {
    const { campaignRow, userType } = this.props;
    const { toaster } = this.state;

    const headerCells = [
      {
        id: 'advertisementId',
        type: 'text',
        label: 'Advertisement Id',
        sortable: false
      },
      { id: 'advertisementName', type: 'text', label: 'Name', sortable: true },
      {
        id: 'advertisementType',
        type: 'text',
        label: 'Type',
        sortable: true,
        processor: getAdTypeDisplayName
      },
      { id: 'updatedAt', type: 'date', hidden: true, sortable: true }
    ];

    const features = {
      enableActions: userType === 'read' ? false : true,
      deleteActionHandler: this.handleAdvertisementDelete,
      editActionHandler: this.handleAdvertisementEdit,
      defaultSortColumn: 'updatedAt',
      defaultSortOrder: 'desc'
    };
    return (
      <Container>
        <Toaster
          message={toaster.message}
          type={toaster.type}
          open={toaster.show}
          handleClose={this.handleToasterClose}
        />
        {this.state.list ? (
          <React.Fragment>
            <div>
              <Grid container spacing={0}>
                <Grid item xs={8} align="left">
                  <ViewHeader>Advertisement List</ViewHeader>
                </Grid>
                {userType === 'read' ? null : (
                  <Grid item xs={4} align="right">
                    <StyledButton
                      variant="contained"
                      color="primary"
                      size="medium"
                      startIcon={<AddIcon />}
                      onClick={() => this.handleAvertisementAdd(campaignRow)}
                      id={`add_ad_${campaignRow._id}_btn`}
                    >
                      Add Advertisement
                    </StyledButton>
                  </Grid>
                )}
              </Grid>
            </div>
            {campaignRow && campaignRow.advertisements.length ? (
              <CustomTable
                list={campaignRow.advertisements}
                headCells={headerCells}
                features={features}
                id={`ad_list_${campaignRow._id}`}
              />
            ) : null}
          </React.Fragment>
        ) : null}
        {this.state.openDialog.delete ? (
          <Dialog
            open={this.state.openDialog.delete}
            message="Are you sure you want to delete the advertisement?"
            handleClose={this.handleDeleteDialogClose}
            handleOkay={this.handleDeleteDialogOkay}
            closeText="no"
            okayText="yes"
          />
        ) : null}
      </Container>
    );
  }
}

const getAdTypeDisplayName = row => {
  const ad = ADVERTISEMENT_TYPE.find(
    type => type.code === row.advertisementType
  );

  return ad && ad.name;
};

export default Advertisement;
