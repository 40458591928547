import { combineReducers } from 'redux';
import campaign from './campaignReducer';
import advertisement from './advertisementReducer';
import advertisementConfig from './advertisementConfigReducer';
import login from './loginReducer';
import user from './userReducer';
import pagesToInclude from './pagesToIncludeReducer';

export default combineReducers({
  campaign,
  advertisement,
  advertisementConfig,
  login,
  user,
  pagesToInclude
});
