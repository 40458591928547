import axios from 'axios';
import { API_BASE_URL } from '../constants';
import * as actions from './index';

export const getPagesToInclude = () => {
  return dispatch => {
    dispatch(actions.getPagesToIncludeStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/include-pages`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.getPagesToIncludeSuccess(response.data));
        } else {
          dispatch(actions.getPagesToIncludeError());
        }
      })
      .catch(function(e) {
        dispatch(actions.getPagesToIncludeError());
      });
  };
};
