import { createAction } from 'redux-actions';
import * as actionTypes from '../constants/actionTypes';

export const addCampaignRequestStart = createAction(
  actionTypes.ADD_CAMPAIGN_REQUEST_START
);
export const addCampaignRequestSuccess = createAction(
  actionTypes.ADD_CAMPAIGN_SUCCESS
);
export const addCampaignRequestError = createAction(
  actionTypes.ADD_CAMPAIGN_ERROR
);

export const listCampaignRequestStart = createAction(
  actionTypes.LIST_CAMPAIGN_REQUEST_START
);
export const listCampaignRequestSuccess = createAction(
  actionTypes.LIST_CAMPAIGN_SUCCESS
);
export const listCampaignRequestError = createAction(
  actionTypes.LIST_CAMPAIGN_ERROR
);

export const deleteCampaignRequestStart = createAction(
  actionTypes.DELETE_CAMPAIGN_REQUEST_START
);
export const deleteCampaignRequestSuccess = createAction(
  actionTypes.DELETE_CAMPAIGN_SUCCESS
);
export const deleteCampaignRequestError = createAction(
  actionTypes.DELETE_CAMPAIGN_ERROR
);

export const addAdvertisementRequestStart = createAction(
  actionTypes.ADD_ADVERTISEMENT_REQUEST_START
);
export const addAdvertisementRequestSuccess = createAction(
  actionTypes.ADD_ADVERTISEMENT_SUCCESS
);
export const addAdvertisementRequestError = createAction(
  actionTypes.ADD_ADVERTISEMENT_ERROR
);

export const getAdvertisementStart = createAction(
  actionTypes.GET_ADVERTISEMENT_START
);

export const getAdvertisementSuccess = createAction(
  actionTypes.GET_ADVERTISEMENT_SUCCESS
);

export const getAdvertisementError = createAction(
  actionTypes.GET_ADVERTISEMENT_ERROR
);
export const deleteAdvertisementRequestStart = createAction(
  actionTypes.DELETE_ADVERTISEMENT_REQUEST_START
);
export const deleteAdvertisementRequestSuccess = createAction(
  actionTypes.DELETE_ADVERTISEMENT_SUCCESS
);
export const deleteAdvertisementRequestError = createAction(
  actionTypes.DELETE_ADVERTISEMENT_ERROR
);

export const getAdvertisementConfigDataStart = createAction(
  actionTypes.GET_ADDVERTISEMENT_CONFIG_DATA_REQUEST_START
);

export const getAdvertisementConfigDataSuccess = createAction(
  actionTypes.GET_ADDVERTISEMENT_CONFIG_DATA_SUCCESS
);

export const getAdvertisementConfigDataError = createAction(
  actionTypes.GET_ADDVERTISEMENT_CONFIG_DATA_ERROR
);

export const getVideoContentStart = createAction(
  actionTypes.GET_VIDEO_CONTENT_REQUEST_START
);

export const getVideoContentSuccess = createAction(
  actionTypes.GET_VIDEO_CONTENT_SUCCESS
);

export const getVideoContentError = createAction(
  actionTypes.GET_VIDEO_CONTENT_ERROR
);

export const getContentDataStart = createAction(
  actionTypes.GET_CONTENT_DATA_REQUEST_START
);

export const getContentDataSuccess = createAction(
  actionTypes.GET_CONTENT_DATA_SUCCESS
);

export const getContentDataError = createAction(
  actionTypes.GET_CONTENT_DATA_ERROR
);

export const syncCampaignRequestStart = createAction(
  actionTypes.SYNC_CAMPAIGN_REQUEST_START
);

export const syncCampaignRequestSuccess = createAction(
  actionTypes.SYNC_CAMPAIGN_SUCCESS
);

export const syncCampaignRequestError = createAction(
  actionTypes.SYNC_CAMPAIGN_ERROR
);

export const setAlreadyOccupiedAdvertismentValues = createAction(
  actionTypes.SET_ALREADY_OCCUPIED_ADVERTISEMENT_VALUES
);

export const loginRequestStart = createAction(actionTypes.LOGIN_REQUEST_START);

export const loginRequestSuccess = createAction(
  actionTypes.LOGIN_REQUEST_SUCCESS
);

export const loginRequestError = createAction(actionTypes.LOGIN_REQUEST_ERROR);

export const userRoleStart = createAction(actionTypes.USER_ROLE_START);

export const userRoleSuccess = createAction(actionTypes.USER_ROLE_SUCCESS);

export const userRoleError = createAction(actionTypes.USER_ROLE_ERROR);

export const addUserStart = createAction(actionTypes.ADD_USER_START);

export const addUserSuccess = createAction(actionTypes.ADD_USER_SUCCESS);

export const addUserError = createAction(actionTypes.ADD_USER_ERROR);

export const listUsersRequestStart = createAction(actionTypes.LIST_USERS_START);

export const listUsersRequestSuccess = createAction(
  actionTypes.LIST_USERS_SUCCESS
);

export const listUsersRequestError = createAction(actionTypes.LIST_USERS_ERROR);

export const setCollapseCampaignRow = createAction(
  actionTypes.SET_CAMPAIGN_COLLAPSE_ROW
);

export const logout = createAction(actionTypes.LOGOUT_REQUEST);

export const getPagesToIncludeStart = createAction(
  actionTypes.GET_PAGES_TO_INCLUDE_START
);
export const getPagesToIncludeSuccess = createAction(
  actionTypes.GET_PAGES_TO_INCLUDE_SUCCESS
);
export const getPagesToIncludeError = createAction(
  actionTypes.GET_PAGES_TO_INCLUDE_ERROR
);
