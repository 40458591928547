export const AIRLINE_ALLOCAION_OPTIONS = [
  {
    name: 'ID'
  },
  {
    name: 'JT'
  },
  {
    name: 'GA'
  },
  {
    name: 'IU'
  },
  {
    name: 'IP'
  }
];
export const CONTENT = [
  {
    name: 'content-1'
  },
  {
    name: 'content-2'
  },
  {
    name: 'content-3'
  }
];

export const ADVERTISEMENT_TYPE = [
  {
    name: 'Masthead Footer',
    code: 'footer'
  },
  {
    name: 'Leaderboard',
    code: 'leaderboard'
  },
  {
    name: 'Pop-Up',
    code: 'popUp'
  },
  {
    name: 'Pre-Roll',
    code: 'preroll'
  },
  {
    name: 'Mid-Roll',
    code: 'midroll'
  },
  {
    name: 'Discovery',
    code: 'discovery'
  }
];

export const PAGES_TO_INCLUDE = [
  {
    name: 'App Home Page',
    code: 'appHomePage'
  },
  {
    name: 'Home Page',
    code: 'homePage'
  },
  {
    name: 'Haji Umrah Home Page',
    code: 'hajiUmrahHomePage'
  },
  {
    name: 'On Board Chat',
    code: 'onBoardChat'
  },
  {
    name: 'Movie Listing Page',
    code: 'movieListPage'
  },
  {
    name: 'Movie Detail page',
    code: 'movieDetailPage'
  },
  {
    name: 'Tv Shows Listing Page',
    code: 'tvShowsListPage'
  },
  {
    name: 'Tv Shows Detail Page',
    code: 'tvShowsDetailPage'
  },
  {
    name: 'Tv Shows Episode List Page',
    code: 'tvShowsEpisodeListPage'
  },
  {
    name: 'Lifestyle Listing Page',
    code: 'LifestyleListPage'
  },
  {
    name: 'Lifestyle Episode Listing Page',
    code: 'LifestyleEpisodeListPage'
  },
  {
    name: 'Lifestyle Detail Page',
    code: 'LifestyleDetailPage'
  },
  {
    name: 'Kids Listing Page',
    code: 'kidsListPage'
  },
  {
    name: 'Kids Detail Page',
    code: 'kidsDetailPage'
  },
  {
    name: 'Kids Episode List Page',
    code: 'kidsEpisodeListPage'
  },
  {
    name: 'Game Listing Page',
    code: 'gameListPage'
  },
  {
    name: 'Magazine Listing Page',
    code: 'magazineListPage'
  },
  {
    name: 'Magazine Edition Listing Page',
    code: 'magazineEditionListPage'
  },
  {
    name: 'Vloggers Listing Page',
    code: 'vloggersListPage'
  },
  {
    name: 'Vloggers Detail Page',
    code: 'vloggersDetailPage'
  },
  {
    name: 'Podcasts Listing Page',
    code: 'podcastsListPage'
  },
  {
    name: 'Podcasts Detail Page',
    code: 'podcastsDetailPage'
  },
  {
    name: 'Tiktok Listing Page',
    code: 'tiktokListPage'
  },
  {
    name: 'Tiktok Detail Page',
    code: 'tiktokDetailPage'
  },
  {
    name: 'We Plus Plan Selection Page',
    code: 'weplusPlanSelectionPage'
  },
  {
    name: 'Podcast Religi Listing Page',
    code: 'podcastReligiListPage'
  },
  {
    name: 'Movie Religi Listing Page',
    code: 'movieReligiListPage'
  },
  {
    name: 'Magazine Religi Listing Page',
    code: 'magazineReligiListPage'
  },
  {
    name: 'Doa Listing Page',
    code: 'doaListPage'
  }
];

export const PLACEMENT_INDEX = [
  {
    name: '1'
  },
  {
    name: '2'
  },
  {
    name: '3'
  },
  {
    name: '4'
  },
  {
    name: '5'
  },
  {
    name: '6'
  },
  {
    name: '7'
  }
];

export const TIME_INTERVAL = [
  {
    name: '5'
  },
  {
    name: '10'
  },
  {
    name: '15'
  },
  {
    name: '20'
  },
  {
    name: '25'
  },
  {
    name: '30'
  },
  {
    name: '35'
  },
  {
    name: '40'
  },
  {
    name: '45'
  },
  {
    name: '50'
  },
  {
    name: '55'
  },
  {
    name: '60'
  },
  {
    name: '65'
  },
  {
    name: '70'
  },
  {
    name: '75'
  },
  {
    name: '80'
  },
  {
    name: '85'
  },
  {
    name: '90'
  },
  {
    name: '95'
  },
  {
    name: '100'
  },
  {
    name: '105'
  },
  {
    name: '110'
  },
  {
    name: '115'
  },
  {
    name: '120'
  }
];

export const MOVIE_CATEGORY = 'movies';
export const LIFESTYLE_CATEGORY = 'lifestyle';
export const TV_SHOW_CATEGORY = 'subtvshows';
export const KIDS_CATEGORY = 'kids';

export const VIDEO_CATEGORIES = [
  {
    name: 'Movies',
    code: MOVIE_CATEGORY
  },
  {
    name: 'TV Shows',
    code: TV_SHOW_CATEGORY
  },
  {
    name: 'Kids',
    code: KIDS_CATEGORY
  },
  {
    name: 'Lifestyle',
    code: LIFESTYLE_CATEGORY
  }
];

export const MOVIES_CATEGORY_LIST = [
  'hollywood',
  'indonesian',
  'korean',
  'bollywood',
  'mandarin'
];

export const VIDEO_CATEGORIES_ADS = ['preroll'];
